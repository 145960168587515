<template>
  <div class="body">
    <header>
      <div class="searchInput">
        <input
          type="text"
          v-model="keyword"
          :placeholder="placeholder"
          @focus="placeholder = ''"
          @blur="placeholder = '搜索科室、医生'"
          @keyup.enter="handleSearch($event)"
        />
        <i class="fangdajing"></i>
      </div>
    </header>
    <section>
      <div class="example-body">
        <div class="grid_title">常用功能</div>
        <div class="grid-item">
          <van-grid :border="false">
            <van-grid-item @click="hospitalProfile">
              <template #text>
                <span class="text">医院简介</span>
              </template>
              <template #icon>
                <i class="yiyuanjianjie"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="hospitalCulture">
              <template #text>
                <span class="text">医院文化</span>
              </template>
              <template #icon>
                <i class="yiyuanwenhua"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="deptSpread">
              <template #text>
                <span class="text">科室分布</span>
              </template>
              <template #icon>
                <i class="keshifenbu"></i>
              </template>
            </van-grid-item>
            <van-grid-item to="/physicianTeam">
              <template #text>
                <span class="text">医师团队</span>
              </template>
              <template #icon>
                <i class="yishituandui"></i>
              </template>
            </van-grid-item>
            <van-grid-item to="/routerHospital">
              <template #text>
                <span class="text">来院路线</span>
              </template>
              <template #icon>
                <i class="laiyuanluxian"></i>
              </template>
            </van-grid-item>
            <van-grid-item>
              <!-- to="/appointNotice" -->
              <template #text>
                <span class="text">预约须知</span>
              </template>
              <template #icon>
                <i class="yuyuexuzhi-disabled"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="hospitalNotice">
              <template #text>
                <span class="text">住院须知</span>
              </template>
              <template #icon>
                <i class="zhuyuanxuzhi"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="medicalNotice">
              <template #text>
                <span class="text">医保须知</span>
              </template>
              <template #icon>
                <i class="yibaoxuzhi"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="medicalDevice">
              <template #text>
                <span class="text">医疗设备</span>
              </template>
              <template #icon>
                <i class="yiliaoshebei"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="recruitList">
              <template #text>
                <span class="text">人才招聘</span>
              </template>
              <template #icon>
                <i class="rencaizhaopin"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="articleNew">
              <template #text>
                <span class="text">健康资讯</span>
              </template>
              <template #icon>
                <i class="jiankangzixun"></i>
              </template>
            </van-grid-item>
            <van-grid-item @click="newsList">
              <template #text>
                <span class="text">院内新闻</span>
              </template>
              <template #icon>
                <i class="yuanneixinwen"></i>
              </template>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="example-body">
        <div class="grid_title">
          按科室找医生
          <span class="allDept" @click="$router.push('/physicianTeam')"
            >全部<van-icon name="arrow"
          /></span>
        </div>
        <div class="grid-item">
          <van-grid :border="false">
            <van-grid-item
              v-for="(item, index) in deptlist"
              :key="index"
              @click="
                $router.push(
                  '/deptPage?title=' + item.name + '&code=' + item.code
                )
              "
            >
              <template #text>
                <span class="text">{{ item.name }}</span>
              </template>
              <template #icon>
                <i :class="item.name"></i>
              </template>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </section>
    <footer>
      <van-tabbar v-model="active" placeholder active-color="#3CDEA6">
        <van-tabbar-item to="/treatmentServices">
          <template #icon="props">
            <i
              :class="props.active ? 'zhenliaofuwu-active' : 'zhenliaofuwu'"
            ></i>
          </template>
          <span class="text">诊疗服务</span>
        </van-tabbar-item>
        <van-tabbar-item to="/medicalGuide">
          <template #icon="props">
            <i :class="props.active ? 'jiuyizhinan-active' : 'jiuyizhinan'"></i>
          </template>
          <span class="text">就医指南</span>
        </van-tabbar-item>
        <van-tabbar-item to="/personalCenter">
          <template #icon="props">
            <i
              :class="props.active ? 'gerenzhongxin-active' : 'gerenzhongxin'"
            ></i>
          </template>
          <span class="text">个人中心</span>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
  </div>
</template>

<script>
export default {
  name: "medicalGuide",
  data() {
    return {
      active: 1,
      keyword: "",
      placeholder: "搜索科室、医生",
      sysconfig: [],
      deptlist: [],
      content_jiankangzixun: [],
      content_yiyuanjieshao: [],
      content_jiuyizhinan: [],
    };
  },
  methods: {
    // 搜索框回车搜索
    handleSearch(e) {
      var evt = window.event || e;
      if (evt.keyCode == 13) {
        //回车事件
        this.$router.push({
          path: "/searchPage",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    // 医院简介
    hospitalProfile() {
      this.$router.push({
        path: "/hospitalProfile",
        query: {
          type: this.content_yiyuanjieshao[0],
          id: this.content_yiyuanjieshao[1],
        },
      });
    },
    // 医院文化
    hospitalCulture() {
      this.$router.push({
        path: "/hospitalCulture",
        query: {
          type: this.content_yiyuanjieshao[0],
          id: this.content_yiyuanjieshao[1],
        },
      });
    },
    // 新闻资讯
    articleNew() {
      this.$router.push({
        path: "/articleNew",
        query: {
          type: this.content_jiankangzixun[0],
          id: this.content_jiankangzixun[1],
        },
      });
    },
    // 院内新闻
    newsList() {
      this.$router.push({
        path: "/hospitalNews",
        query: {
          type: this.content_yiyuanjieshao[0],
          id: this.content_yiyuanjieshao[1],
        },
      });
    },
    // 人才招聘
    recruitList() {
      this.$router.push({
        path: "/recruitList",
        query: {
          type: this.content_yiyuanjieshao[0],
          id: this.content_yiyuanjieshao[1],
        },
      });
    },
    // 医疗设备
    medicalDevice() {
      this.$router.push({
        path: "/medicalDevice",
        query: {
          type: this.content_yiyuanjieshao[0],
          id: this.content_yiyuanjieshao[1],
        },
      });
    },
    // 医保须知
    medicalNotice() {
      this.$router.push({
        path: "/medicalNotice",
        query: {
          type: this.content_jiuyizhinan[0],
          id: this.content_jiuyizhinan[1],
        },
      });
    },
    // 住院须知
    hospitalNotice() {
      this.$router.push({
        path: "/hospitalNotice",
        query: {
          type: this.content_jiuyizhinan[0],
          id: this.content_jiuyizhinan[1],
        },
      });
    },
    // 科室分布
    deptSpread() {
      this.$router.push({
        path: "/deptSpread",
        query: {
          type: this.content_jiuyizhinan[0],
          id: this.content_jiuyizhinan[1],
        },
      });
    },
    // 获取科室列表
    getDeptList() {
      let postData = {
        key: this.keyword,
      };
      this.$http.get("/api/dept", postData).then((res) => {
        if (res.status == 200) {
          this.deptlist = res.data;
          this.deptlist.length = 8;
        }
      });
    },
  },
  mounted() {
    this.sysconfig = JSON.parse(window.localStorage.getItem("sysconfig"));
    let jiankangzixun = this.sysconfig.find(
      (item) => item.paramKey == "content_jiankangzixun"
    );
    let yiyuanjianjie = this.sysconfig.find(
      (item) => item.paramKey == "content_yiyuanjieshao"
    );
    let jiuyizhinan = this.sysconfig.find(
      (item) => item.paramKey == "content_jiuyizhinan"
    );
    this.content_jiankangzixun = jiankangzixun.patientId.split("_");
    this.content_yiyuanjieshao = yiyuanjianjie.patientId.split("_");
    this.content_jiuyizhinan = jiuyizhinan.patientId.split("_");
    this.getDeptList();
  },
};
</script>

<style scoped>
.body {
  background-image: url("../../assets/img/guide/bg.png");
  background-size: contain;
  height: 3.8rem;
}
header {
  font-size: 0.4rem;
  position: relative;
}
.searchInput {
  height: 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  text-align: right;
  width: 9rem;
  background-color: #fff;
  margin: auto;
  position: relative;
  top: 0.3rem;
}
.searchInput input {
  outline: none;
  height: 100%;
  border: none;
}
.example-body {
  display: block;
  margin-top: 0.4rem;
}
.grid_title {
  font-size: 0.44rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0.3rem;
}

.grid_title:before {
  content: "";
  display: inline-block;
  width: 0.1rem;
  height: 0.5rem;
  margin: 0 10px;
  border-radius: 2px;
  background-color: #00d48b;
  position: relative;
  top: 3px;
}
.grid-item {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.van-search {
  background-color: transparent;
}
.text {
  font-size: 0.3rem;
}
/deep/.van-tabbar {
  height: 1.3rem;
}
/deep/.van-grid-item__content {
  height: auto;
}
.allDept {
  float: right;
  font-size: 0.3rem;
  color: rgba(153, 153, 153, 1);
  position: relative;
  top: 3px;
  margin-right: 0.5rem;
}
.allDept .van-icon {
  position: relative;
  top: -1px;
}
</style>
